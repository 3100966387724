import React, {useEffect} from 'react';
import Sidebar from "../components/Layout/Navigation/Sidebar/Sidebar";
import {Helmet} from "react-helmet";
import NewEraLogo from '../assets/images/theatre-new-era/newera-logo.png'
import Navigation from "../components/Layout/Navigation/Navigation";
import Footer from "../components/Layout/Footer";
import '../../translation';
import { useTranslation } from 'react-i18next';

const TheatreNewEra = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])
    const t = useTranslation('theatreNewEra').t;

    return (
        <>
            <Navigation />
            <div className='container-custom page d-flex'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>EIB Campus Pégomas - Centre d'examen Cambridge</title>
                    <meta name="description" content="EIB Campus Pégomas - L'EIB Campus Pégomas est un Centre d’examens de Cambridge et prépare aux diplômes suivants : Starters – Movers – Flyers" />
                </Helmet>
                <Sidebar />
                <div className='content fadeInEarth text'>
                    <div className='content-image text-center'>
                        <img width='300px' src={NewEraLogo} alt='New Era Logo' />
                    </div><br/>
                    <div className='w-100 text-center'>
                        <a href='https://www.neweraacademy.co.uk/nea-syllabus-fees/' target='_blank' className='btn btn-secondary text-center m-auto'>New Era Website</a>
                    </div><br/>
                    <p className='text'>{t('t1')}</p><br/>
                    <h4 className='content-title'>{t('t2')}</h4>
                    <p className='text' dangerouslySetInnerHTML={{__html: t('t3')}}></p><br/>
                    <h4 className='content-title'>{t('t4')}</h4>
                    <p className='text' dangerouslySetInnerHTML={{__html: t('t5')}}></p><br/>
                    <h4 className='content-title'>{t('t6')}</h4>
                    <p className='text' dangerouslySetInnerHTML={{__html: t('t7')}}></p>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default TheatreNewEra;